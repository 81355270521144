header {
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ul {
    margin: 0;
    padding: 0;
    a {
      text-decoration: none;
    }
  }
  a {
    height: 100%;
    img {
      max-height: 100%;
      width: auto;
    }
  }
}
