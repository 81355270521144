.hero {
  & > img {
    max-width: 50%;
  }
  #download-badges {
    display: flex;
    flex-wrap: wrap;
    a {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        height: 60px;
      }
    }
  }
}
