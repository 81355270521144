$details-padding: 1em;

details {
  font-size: 1rem;
  width: 100%;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  width: 100%;
  background: #ffffff;
  border-radius: 0px;
  position: relative;
  &:first-of-type {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }
  &:last-of-type {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  .summary-title {
    user-select: none;
  }

  &:hover {
    cursor: pointer;
  }

  .summary-content {
    border-top: 1px solid #e2e8f0;
    cursor: default;
    padding: $details-padding;
    font-weight: 300;
    line-height: 1.5;
    background-color: #f9f9f9;
  }

  summary {
    padding: $details-padding;

    &:focus {
      outline: none;
    }

    &:hover .summary-chevron-up svg {
      opacity: 1;
    }
  }

  .summary-chevron-up svg {
    opacity: 0.5;
  }

  .summary-chevron-up,
  .summary-chevron-down {
    pointer-events: none;
    position: absolute;
    top: 0.75em;
    right: $details-padding;
    background: #ffffff;

    svg {
      display: block;
    }
  }

  summary::-webkit-details-marker {
    display: none;
  }
}
