footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  ul {
    list-style: none;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px 0px;
    margin: 24px 0px 0px 0px;
    border-top: thin solid rgba(149, 149, 149, 0.5);
    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
    li {
      margin: 0 24px;
      @media screen and (max-width: 767px) {
        margin: 6px 0px;
      }
      a {
        color: #82c788;
        text-decoration: none;
      }
    }
  }
}
